import { BoxI18nProvider } from '@socialchorus/box-components';
import {
  ToastProvider as SolarToastProvider,
  Toast,
  ToastViewport,
  TooltipProvider,
} from '@socialchorus/shared-ui-components';
import { useEffect, useRef, Suspense } from 'react';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import { Router } from 'react-router-dom';
import store from './config/store';
import { createHistory } from './config/history';
import i18n from './config/i18n';
import useHtmlLang from './common/use-html-lang';
import { Spinner } from './components/ui';
import PatronData from './patron-data';
import PatronRoutes from './patron-routes';
import ProgramTheme from './components/program-theme';
import SiteHeader from './components/site-header';
import ErrorBoundary from './components/error-boundary';
import LoadingOverlay from './components/loading-overlay';
import OverlayController from './components/overlay-controller';
import MessageController from './components/message-controller';
import OnboardingController from './components/onboarding-controller';

import NativeRedirect, {
  NativeWebviewRedirect,
} from './screens/native-redirect';

import './patron.scss';
import { manageIsTabbingOnBody } from './common/manage-is-tabbing-on-body';
import AssistantError from './screens/assistant/assistant-error';
import RouterHistoryTracker from './components/router-history-tracker';
import { SkipToContentLink } from './components/skip-to-content/skip-to-content';
import { Toaster } from './components/v2/toaster/toaster';
import { ToasterReduxShim } from './components/v2/toaster/deprecation-helper';
import { isInMSTeams } from './lib/teams-helper';
import { isInMSTeamsMobile } from './lib/teams-helper';
import { IntegrationModalInit } from './components/ui/shortcut/modal/integration-modal-init';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours,
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

// This is the key part - define custom dehydrate options to filter out error queries
const persistOptions = {
  persister,
  dehydrateOptions: {
    shouldDehydrateQuery: () => {
      // Persist all queries, even if they are in an error state. Otherwise,
      // the query will not be persisted and will not be restored on refresh/reopen.
      return true;
    },
  },
};

const PatronContainer = ({ bootstrapData }) => (
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={persistOptions}
  >
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <RecoilRoot>
        <Provider store={store}>
          <ErrorBoundary>
            <TooltipProvider>
              <SolarToastProvider>
                <BoxI18nProvider language={i18n.language}>
                  <Suspense fallback={<LoadingOverlay />}>
                    <Patron bootstrapData={bootstrapData} />
                  </Suspense>
                </BoxI18nProvider>
              </SolarToastProvider>
            </TooltipProvider>
          </ErrorBoundary>
        </Provider>
      </RecoilRoot>
    </>
  </PersistQueryClientProvider>
);

const Patron = ({ bootstrapData }) => {
  useEffect(manageIsTabbingOnBody, []);

  useHtmlLang(i18n.language);

  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIos = /iPhone|iPod/i.test(navigator.userAgent);

  let devicePlatform = 'web';
  if (isAndroid) {
    devicePlatform = 'android';
  } else if (isIos) {
    devicePlatform = 'ios';
  }

  const isIosWebview = /advomobo/.test(navigator.userAgent);
  const isAndroidWebview = /SocialChorus|advodroid/.test(navigator.userAgent);
  const isMobileWebview = isIosWebview || isAndroidWebview;

  const {
    config: { program_path: basename } = {},
    session: { advocate_id: advocateId } = {},
    config = {},
    program = {},
  } = bootstrapData;

  const basepath = isInMSTeamsMobile ? `${basename}/mft` : basename;

  const isWebviewPath = window.location.pathname.includes('/webview/');

  const showNativeRedirect =
    program.patron_deeplink_enabled &&
    (isAndroid || isIos) &&
    !isMobileWebview &&
    !isWebviewPath &&
    !isInMSTeams &&
    !window.location.search.includes('useMobileWeb=true') &&
    sessionStorage.getItem('useMobileWeb') !== 'true';

  const history = useRef(
    createHistory({ hashType: 'noslash', basename: basepath })
  ); // If we're in a webview, we want to redirect to a native screen. Except for
  // pages that are specifically designed for webviews
  if (isMobileWebview && !isWebviewPath) {
    return (
      <Router history={history.current}>
        <NativeWebviewRedirect platform={devicePlatform} program={program} />
      </Router>
    );
  }

  return showNativeRedirect ? (
    <Router history={history.current}>
      <NativeRedirect platform={devicePlatform} program={program} />
    </Router>
  ) : (
    <PatronData config={config} program={program} advocateId={advocateId}>
      {({ isFetching, isError }) => (
        <Router history={history.current}>
          {isError ? (
            <div className="container fetching-error-container">
              <AssistantError inline />
            </div>
          ) : (
            <>
              <SkipToContentLink />
              <SiteHeader loading={isFetching} />
              <MessageController />
              <ToasterReduxShim />
              <Toaster />
              <ToastViewport />
              <RouterHistoryTracker />
              {isFetching ? (
                <div className={'page'}>
                  <Spinner center />
                </div>
              ) : (
                <>
                  <ProgramTheme />
                  <ErrorBoundary>
                    <PatronRoutes />
                  </ErrorBoundary>

                  <OverlayController />
                  <IntegrationModalInit />
                  {!isMobileWebview && <OnboardingController />}
                </>
              )}
            </>
          )}
        </Router>
      )}
    </PatronData>
  );
};

export default PatronContainer;
