import cx from 'classnames';
import debounce from 'lodash/debounce';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventListener } from 'usehooks-ts';

import useAssistantActionHandlers from '../../../common/use-assistant-action-handlers';
import useElementSize from '../../../common/use-element-size';
import { usePatronSelector } from '../../../common/use-patron-selector';
import { trackAssistantIntegrationLinkClick } from '../../../models/assistant/analytics';
import { Button } from '../../ui';
import ShortcutButton from './shortcut-button/shortcut-button';
import './homepage-shortcuts.scss';
import { useLinkNavigation } from '../../../common/use-link-navigation';
import useScreenSize from '../../../common/use-screen-size';
import { useDispatch } from 'react-redux';
import { uiOperations } from '../../../models/ui';
import { ID as ShortcutMobileListModalId } from '../../ui/shortcut/modal/shortcut-mobile-list-modal';
import useFeatureFlag from '../../../common/use-feature-flag';
import { Feature } from '../../../models/features/features';
import { useRecoilState } from 'recoil';
import { shortcutHeaderOpen } from '../../../models/home-page/recoil-state';
import { useIntegrationsQuery } from '../../assistant/queries';
import { Alert } from '@socialchorus/shared-ui-components';

const CONTAINER_MAX_HEIGHT = 102;
const HomepageShortcuts = () => {
  const { handleAction } = useAssistantActionHandlers();
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const dispatch = useDispatch();

  const notificationCenterEnabled = useFeatureFlag(
    Feature.NOTIFICATION_CENTER_ENABLED
  );

  const {
    data: integrations,
    isError,
    isRefetching,
    isOldData,
  } = useIntegrationsQuery();

  const [isOpen, setIsOpen] = useRecoilState(shortcutHeaderOpen);
  const [showMoreIcon, setShowMoreIcon] = useState(true);
  const [setContainerRef, { scrollHeight }] = useElementSize();
  const initialContainerRef = useRef<HTMLDivElement | null>(null);

  const setContainerRefCallback = useCallback(
    (ref) => {
      setContainerRef(ref);
      initialContainerRef.current = ref;
    },
    [setContainerRef]
  );

  const containerClasses = cx('shortcuts-grid', {
    open: isOpen,
  });
  const shortcutClasses = cx('shortcut');

  const moreIcon = isOpen ? 'expand_less' : 'expand_more';
  const isContainerOverflowing = scrollHeight > CONTAINER_MAX_HEIGHT;

  const debouncedSetShowMoreIconWhenOverflowing = useCallback(() => {
    return debounce(() => {
      setShowMoreIcon(isContainerOverflowing);
    }, 250);
  }, [isContainerOverflowing]);

  useLayoutEffect(() => {
    setShowMoreIcon(isContainerOverflowing);
  }, [isContainerOverflowing]);

  useEventListener('resize', debouncedSetShowMoreIconWhenOverflowing);

  const handleMoreClick = () => {
    if (isMobile && notificationCenterEnabled && !isOpen) {
      dispatch(uiOperations.displayOverlay(ShortcutMobileListModalId));
    } else {
      setIsOpen((o) => !o);
    }
  };
  const getLinkNavigation = useLinkNavigation();

  // If data is "Fresh" (less than 1 hour old): show and update when it comes back
  // If data is "Old": show skeleton and update when it comes back ( either way to show cache or fresh)
  // If there is no data: show nothing until we know that there are shortcuts ( it comes back)
  if (!integrations || integrations.length === 0) {
    return null;
  }

  if (isOldData && isRefetching) {
    return <ShortcutSkeleton />;
  }

  return (
    <nav className="shortcuts" aria-label={t('navigation.shortcuts')}>
      {isError && (
        <div className="shortcuts-alert">
          <Alert
            type="warning"
            size="compact"
            title="Some Shortcuts may not be available due to outages"
          />
        </div>
      )}
      <div className="shortcuts-container">
        <div className={containerClasses} ref={setContainerRefCallback}>
          {integrations.map((integration) => {
            const isTLC = integration?.subject?.integrationType === 'link';
            const integrationActions = integration?.subject?.buttons;

            const directAction = isTLC && integrationActions?.length === 1;

            const directActionClick = () => {
              if (directAction) {
                const button = integrationActions[0];

                trackAssistantIntegrationLinkClick({
                  service_id: integration.id,
                  service_name: integration.subject.title,
                  link_name: button.button_text,
                  order: 0,
                });

                handleAction(integrationActions[0]?.action);
              }
            };

            //when its a 1-link TLC, we want to use something that handles the action interface (handleAction), and so need an onClick
            //otherwise, just link to the assistant. it is important to use href for this as the underlaying Button component will handle internally linking to the app.
            const onClick = directAction ? directActionClick : undefined;
            const href = directAction
              ? undefined
              : getLinkNavigation(`/assistant/service/${integration?.id}`);

            return (
              <ShortcutButton
                id={integration?.id}
                iconSrc={integration?.subject?.image_url}
                label={integration?.name}
                href={href}
                onClick={onClick}
                key={integration?.id}
                className={shortcutClasses}
              />
            );
          })}
        </div>
        {showMoreIcon || isOpen ? (
          <Button
            className={'shortcuts__more-icon'}
            theme={'icon-only'}
            icon={moreIcon}
            onClick={handleMoreClick}
          />
        ) : null}
      </div>
    </nav>
  );
};

const ShortcutSkeleton = () => {
  // Display multiple skeleton items to mimic the shortcut tray
  const skeletonItems = Array.from({ length: 6 }).map((_, index) => (
    <div className="shortcut-skeleton__item" key={`skeleton-${index}`}>
      <div className="shortcut-skeleton__icon" />
      <div className="shortcut-skeleton__label" />
    </div>
  ));

  return (
    <nav className="shortcuts" aria-label="Loading shortcuts">
      <div className="shortcuts-container shortcut-skeleton">
        {skeletonItems}
      </div>
    </nav>
  );
};

export default HomepageShortcuts;
