import { JSXElementConstructor, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { homePageVisitAnalyticsSent } from '../../models/home-page/recoil-state';
import { channelSelectors } from '../../models/channels';
import withRerenderPrevent from '../../common/with-rerender-prevent';
import useScreenTitle from '../../common/use-screen-title';
import { trackHomeLoad } from '../../models/analytics';
import useHomeLinks from '../../common/use-home-links';
import useHomeFeedSections from '../../common/use-home-feed-sections';
import useContentFeed from '../../common/use-content-feed';
import { Spinner } from '../../components/ui';
import HomeQuickLinks from './home-quick-links';
import HomeFeeds from './home-feeds';
import HomeChannelFeed from './home-channel-feed';
import ViewTrigger from '../../components/view-trigger';
import './home.scss';
import { Feature } from '../../models/features/features';
import HomepageShortcuts from '../../components/v2/homepage-shortcuts/homepage-shortcuts';
import { HomeSection } from '../../components/v2/home-section';
import useNavigationRefetch from '../../common/use-navigation-refetch';
import { RootPatronState } from '../../common/use-patron-selector';
import useFeatureFlag from '../../common/use-feature-flag';

type HomeProps = ReturnType<typeof mapStateToProps>;
const Home = ({
  recommendedChannelId,
  newestChannelId,
  popularChannelId,
}: HomeProps) => {
  useScreenTitle();

  const [visitedAnalyticsSent, setVisitedAnalyticsSent] = useRecoilState(
    homePageVisitAnalyticsSent
  );

  useEffect(() => {
    if (!visitedAnalyticsSent) {
      trackHomeLoad();
      setVisitedAnalyticsSent(true);
    }
  }, [visitedAnalyticsSent, setVisitedAnalyticsSent]);

  const { t } = useTranslation();

  const { isFetching: homeLinksIsFetching, fetchHomeLinks } = useHomeLinks();
  const { isFetching: feedSectionsIsFetching, refetch: fetchHomeFeedSections } =
    useHomeFeedSections();

  const newestTopicEnabled = useFeatureFlag(Feature.NEWEST_TOPIC_ENABLED);
  const recommendedTopicEnabled = useFeatureFlag(
    Feature.RECOMMENDED_TOPIC_ENABLED
  );
  const popularTopicEnabled = useFeatureFlag(Feature.POPULAR_TOPIC_ENABLED);

  const {
    fetching: recommendedIsFetching,
    loaded: recommendedIsLoaded,
    feedItems: recommendedContents,
    fetchFeed: fetchRecommendedContents,
  } = useContentFeed({
    channelId: String(recommendedChannelId),
    perPage: 4,
    manualFetch: true,
  });

  const {
    fetching: newestIsFetching,
    loaded: newsIsLoaded,
    feedItems: newestContents,
    fetchFeed: fetchNewestContents,
  } = useContentFeed({
    channelId: newestChannelId,
    perPage: 4,
    manualFetch: true,
  });

  const {
    fetching: popularIsFetching,
    loaded: popularIsLoaded,
    feedItems: popularContents,
    fetchFeed: fetchPopularContents,
  } = useContentFeed({
    channelId: popularChannelId,
    perPage: 4,
    manualFetch: true,
  });

  /*
   *  New UI Stuff
   */
  const newTopicsString = useFeatureFlag(Feature.CHANNELS_TO_TOPICS_ENABLED);
  const recommendedChannelTitle = newTopicsString
    ? 'landing.recommended_topic'
    : 'landing.recommended_channel';
  const newestChannelTitle = newTopicsString
    ? 'landing.newest_topic'
    : 'landing.newest_channel';
  const mostPopularChannelString = newTopicsString
    ? 'landing.most_popular_topic'
    : 'landing.most_popular_channel';

  const newUIEnabled = useFeatureFlag(Feature.NEW_UI_ENABLED);

  const quickLinksDisabled = useFeatureFlag(Feature.QUICK_LINKS_DISABLED);

  const newShortcutsEnabled =
    useFeatureFlag(Feature.NEW_SHORTCUTS_ENABLED) && newUIEnabled;

  const [shouldRefetchHomeFeeds, setShouldRefetchHomeFeeds] = useState(false);

  useNavigationRefetch(() => {
    fetchHomeLinks();
    fetchHomeFeedSections();
    fetchRecommendedContents(true);
    fetchNewestContents(true);
    fetchPopularContents(true);
    setShouldRefetchHomeFeeds(true);
  });

  const quickLinksClassName = cx('home__quick-links', {
    'home__quick-links--v2': newUIEnabled,
  });
  /*
   *  End of New UI Stuff
   */

  const ChannelsComponent = newUIEnabled ? HomeSection : HomeChannelFeed;

  return (
    <div className="home page">
      {/* screen reader only */}
      <h1 className="sr-only">{t('nav_links.home')}</h1>

      {/* QuickLinks Section - only depends on homeLinksIsFetching */}
      {!quickLinksDisabled && (
        <div className={quickLinksClassName}>
          <div className="container">
            {homeLinksIsFetching ? <Spinner /> : <HomeQuickLinks />}
          </div>
        </div>
      )}

      {/* Homepage Shortcuts Section - loads independently */}
      {newShortcutsEnabled && <HomepageShortcuts />}

      {/* Home Feeds Section - depends on feedSectionsIsFetching */}
      <div className="home__feeds-container">
        {feedSectionsIsFetching ? (
          <Spinner />
        ) : (
          <HomeFeeds
            shouldRefetch={shouldRefetchHomeFeeds}
            onRefetch={() => setShouldRefetchHomeFeeds(false)}
          />
        )}
      </div>

      {/* Channels Section - each channel loads independently */}
      <div className="home__channels">
        <div className="container">
          {recommendedTopicEnabled && recommendedChannelId && (
            <>
              {!recommendedIsLoaded && (
                <ViewTrigger
                  offset="200px"
                  onInview={fetchRecommendedContents}
                />
              )}
              <ChannelsComponent
                title={t(recommendedChannelTitle)}
                channelId={String(recommendedChannelId)}
                contents={recommendedContents}
                minLength={1}
                maxLength={4}
                isFetching={recommendedIsFetching}
                analyticsData={{ location: 'home_channel_recommended' }}
              />
            </>
          )}

          {newestTopicEnabled && newestChannelId && (
            <>
              {!newsIsLoaded && (
                <ViewTrigger offset="200px" onInview={fetchNewestContents} />
              )}
              <ChannelsComponent
                title={t(newestChannelTitle)}
                channelId={newestChannelId}
                contents={newestContents}
                minLength={1}
                maxLength={4}
                isFetching={newestIsFetching}
                analyticsData={{ location: 'home_channel_newest' }}
              />
            </>
          )}

          {popularTopicEnabled && popularChannelId && (
            <>
              {!popularIsLoaded && (
                <ViewTrigger offset="200px" onInview={fetchPopularContents} />
              )}
              <ChannelsComponent
                title={t(mostPopularChannelString)}
                channelId={popularChannelId}
                contents={popularContents}
                minLength={1}
                maxLength={4}
                isFetching={popularIsFetching}
                analyticsData={{ location: 'home_channel_popular' }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  recommendedChannelId:
    channelSelectors.getChannelIdFirstUniqRecommended(state),
  newestChannelId: channelSelectors.getChannelIdFirstUniqNewest(state),
  popularChannelId: channelSelectors.getChannelIdFirstUniqPopular(state),
});

export default compose<JSXElementConstructor<HomeProps>>(
  connect(mapStateToProps, null),
  withRerenderPrevent
)(Home);
