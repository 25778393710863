import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchCommands, fetchIntegrations } from '../../services/assistant-v2';
import { AssistantResponse } from '../../models/assistant/interfaces/AssistantResponse';
import { Service } from '../../models/assistant/interfaces/subject/Service';

const OLD_DATA_THRESHOLD = 1000 * 60 * 60; // 1 hour

/**
 * Custom hook to fetch integrations with proper TypeScript typing
 * @param programId - The program ID to fetch integrations for
 * @param userId - The user ID to fetch integrations for
 * @param options - Optional query options
 * @returns Typed query result containing integrations data
 */
export const useIntegrationsQuery = (
  options?: Omit<
    UseQueryOptions<
      Array<AssistantResponse<Service>>,
      Error,
      Array<AssistantResponse<Service>>,
      string[]
    >,
    'queryKey' | 'queryFn'
  >
): {
  data: Array<AssistantResponse<Service>> | undefined;
  isOldData: boolean;
  isLoading: boolean;
  isError: boolean;
  isRefetching: boolean;
  isFetching: boolean;
} => {
  const query = useQuery<
    Array<AssistantResponse<Service>>,
    Error,
    Array<AssistantResponse<Service>>,
    string[]
  >({
    queryKey: ['assistant/integrations'],
    queryFn: async () => fetchIntegrations(),
    keepPreviousData: true,
    ...options,
  });

  // Data is old if it is a previous data and it is older than 1 hour ago
  const isOldData =
    !!query.isPreviousData &&
    !!query.dataUpdatedAt &&
    query.dataUpdatedAt < Date.now() - OLD_DATA_THRESHOLD;

  return {
    data: query.data,
    isOldData,
    isLoading: query.isLoading,
    isError: query.isError,
    isFetching: query.isFetching,
    isRefetching: query.isRefetching,
  };
};

export const useCommandsQuery = () =>
  useQuery({
    queryKey: ['assistant/commands'],
    queryFn: () => fetchCommands(),
  });
