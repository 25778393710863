import { AxiosRequestConfig, AxiosResponse } from 'axios';

import eva from '../config/eva';
import { ApiRequest } from '../models/assistant/interfaces/ApiRequest';
import { AssistantResponse } from '../models/assistant/interfaces/AssistantResponse';
import {
  SearchProvider,
  SearchResponse,
} from '../models/assistant/interfaces/SearchResponse';
import { Inbox } from '../models/assistant/interfaces/subject/Inbox';
import { Service } from '../models/assistant/interfaces/subject/Service';
import { CommandButton } from '../models/assistant/interfaces/Command';
import { Card } from '../models/assistant/interfaces/subject/Summary';
import { Pageable } from '../models/assistant/interfaces/Pageable';
import { SearchHistory } from '../models/assistant/interfaces/SearchHistory';

/** Type for request parameters in axios config */
type RequestParams = AxiosRequestConfig['params'];

/**
 * Transforms API response by extracting data property
 * @param res - Axios response containing data object
 * @returns The extracted data
 */
const transformResponse = <T>(res: AxiosResponse<{ data: T }>): T => {
  return res.data.data;
};

/**
 * Gets the unscoped base URL by removing program path components
 * @returns The unscoped base URL string
 */
const getUnscopedBaseUrl = (): string | undefined => {
  return eva.defaults.baseURL?.replace(/\/api\/v1\/programs\/\d+/, '/');
};

/**
 * Fetches available commands for the assistant
 * @param params - Optional request parameters
 * @returns Promise resolving to array of CommandButton objects
 */
export const fetchCommands = (
  params?: RequestParams
): Promise<CommandButton[]> => {
  return eva
    .get<{ data: CommandButton[] }>(`/assistant/commands`, {
      params,
    })
    .then(transformResponse);
};

/**
 * Fetches bootstrap data for the assistant
 * @param params - Optional request parameters
 * @returns Promise resolving to bootstrap data
 */
export const fetchBootstrap = (params?: RequestParams) => {
  return eva
    .get(`/assistant/bootstrap`, {
      params,
    })
    .then(transformResponse);
};

/**
 * Fetches inbox items for the assistant
 * @param params - Optional request parameters
 * @returns Promise resolving to array of inbox items
 */
export const fetchInbox = (
  params?: RequestParams
): Promise<AssistantResponse<Inbox>[]> => {
  return eva
    .get<{ data: AssistantResponse<Inbox>[] }>(`/assistant/inbox`, {
      params,
    })
    .then(transformResponse);
};

/**
 * Fetches the count of inbox items
 * @param params - Optional request parameters
 * @returns Promise resolving to response containing count
 */
export const fetchInboxCount = (
  params?: RequestParams
): Promise<AxiosResponse<{ count: number }>> => {
  return eva.get<{ count: number }>(`/assistant/inbox/count`, {
    params,
  });
};

/**
 * Fetches quick actions for the assistant
 * @param params - Optional request parameters
 * @returns Promise resolving to array of quick action services
 */
export const fetchQuickActions = (
  params?: RequestParams
): Promise<AssistantResponse<Service>[]> => {
  return eva
    .get<{ data: AssistantResponse<Service>[] }>(`/assistant/quick_actions`, {
      params,
    })
    .then(transformResponse);
};

/**
 * Fetches notifications for the assistant
 * @param params - Optional request parameters
 * @returns Promise resolving to notification data
 */
export const fetchNotifications = (params?: RequestParams) => {
  return eva.get(`/assistant/notifications`, {
    params,
  });
};

/**
 * Fetches answered polls for the assistant
 * @param params - Optional request parameters
 * @returns Promise resolving to response with polls data and pagination
 */
export const fetchAnsweredPolls = (params?: RequestParams) => {
  return eva.get<{ data: AssistantResponse<Card>[]; meta: Pageable }>(
    `/assistant/polls/answered`,
    {
      params,
    }
  );
};

/**
 * Fetches todos for the assistant
 * @param params - Optional request parameters
 * @returns Promise resolving to todos data
 */
export const fetchTodos = (params?: RequestParams) => {
  return eva.get(`/assistant/todos`, {
    params,
  });
};

/**
 * Fetches a specific todo by ID
 * @param todoId - ID of the todo to fetch
 * @param params - Optional request parameters
 * @returns Promise resolving to todo data
 */
export const fetchTodo = (todoId: string, params?: RequestParams) => {
  return eva.get(`/assistant/todos/${todoId}`, {
    params,
  });
};

/**
 * Fetches available search providers
 * @returns Promise resolving to array of search providers
 */
export const fetchSearchProviders = (): Promise<SearchProvider[]> => {
  return eva
    .get<{ data: SearchProvider[] }>(`/assistant/search_providers`)
    .then(transformResponse);
};

/**
 * Fetches available integrations
 * @param params - Optional request parameters
 * @returns Promise resolving to integration service responses
 */
export const fetchIntegrations = (
  params?: RequestParams
): Promise<AssistantResponse<Service>[]> => {
  return eva
    .get<{ data: AssistantResponse<Service>[] }>('/assistant/integrations', {
      params,
    })
    .then(transformResponse);
};

/**
 * Fetches a specific integration by ID
 * @param integrationId - ID of the integration to fetch
 * @param params - Optional request parameters
 * @returns Promise resolving to integration data
 */
export const fetchIntegration = (
  integrationId: string,
  params?: RequestParams
) => {
  return eva
    .get(`/assistant/integrations/${integrationId}`, {
      params,
    })
    .then(transformResponse);
};

/**
 * Fetches details for a specific command
 * @param integrationId - ID of the integration
 * @param commandName - Name of the command
 * @param params - Optional request parameters
 * @returns Promise resolving to command button details
 */
export const fetchCommandDetail = (
  integrationId: string,
  commandName: string,
  params?: RequestParams
): Promise<CommandButton> => {
  return eva
    .get<{ data: CommandButton }>(
      `/assistant/integrations/${integrationId}/command/${commandName}`,
      {
        params,
      }
    )
    .then(transformResponse);
};

/**
 * Fetches summary details from a URL
 * @param url - URL to fetch summary for
 * @returns Promise resolving to summary data
 */
export const fetchSummaryDetail = (url: string) => {
  const reqUrl = url.split('/api/v1')[1];
  return eva.get(reqUrl);
};

/**
 * Performs a search with the assistant
 * @param searchParams - Search parameters
 * @returns Promise resolving to search response or assistant response array
 */
export const search = (
  params?: RequestParams
): Promise<SearchResponse | { data: AssistantResponse[] }> => {
  return eva
    .post(`/assistant/search`, {
      ...params,
    })
    .then((res) => res.data);
};

/**
 * Fetches user's previous searches
 * @param params - Optional request parameters
 * @returns Promise resolving to search history
 */
export const fetchUserPreviousSearches = (
  params?: RequestParams
): Promise<SearchHistory> => {
  return eva
    .get<{ data: SearchHistory }>(`/assistant/search_history`, {
      params,
    })
    .then(transformResponse);
};

/**
 * Handles API requests based on provided request object
 * @param request - API request configuration
 * @param baseUrl - Base URL for the request
 * @param additionalData - Additional data to include in the request
 * @returns Promise resolving to request response
 */
export const handleRequest = (
  request: ApiRequest,
  baseUrl: string,
  additionalData?: Record<string, unknown>
) => {
  const data = {
    ...additionalData,
    ...(request.payload_string ? JSON.parse(request.payload_string) : null),
  };
  return eva.request({
    url: request.endpoint,
    method: request.method,
    responseType: request.responseType || 'json',
    baseURL: baseUrl || getUnscopedBaseUrl(),
    data,
  });
};
