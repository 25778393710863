import { Button } from '@socialchorus/shared-ui-components';
import { useState, useEffect, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { Comment, CommentWithReplies } from '../../../models/comments/types';
import { CommentContext } from '../../comments/types';

import CommentListItem from './comment-list-item';
import { getFeatureFlag } from '../../../models/features/features';
import { usePatronSelector } from '../../../common/use-patron-selector';
import { Feature } from '../../../models/features/features';
import { FlashMessage } from '../../comments/comment-flash-message';

type CommentRepliesProps = {
  comment: Comment | CommentWithReplies;
  nestedLevel: number;
  context: CommentContext;
  onEdit?: (comment: Comment) => void;
  onDelete?: (comment: Comment) => void;
  onReply?: (comment: Comment, ref: RefObject<HTMLDivElement>) => void;
  onReport?: (comment: Comment) => void;
  onReplies?: (comment: Comment | CommentWithReplies) => Promise<void>;
  onSubmit?: (comment: Comment) => void;
  onError?: (err: FlashMessage) => void;
};

const CommentReplies = ({
  comment,
  nestedLevel,
  context,
  onEdit,
  onDelete,
  onReport,
  onReplies,
  onReply,
  onSubmit,
  onError,
}: CommentRepliesProps) => {
  const [isFetchingReplies, setIsFetchingReplies] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const replyInPlaceEnabled = usePatronSelector((state) =>
    getFeatureFlag(state, Feature.REPLY_IN_PLACE_ENABLED)
  );

  const { t } = useTranslation();

  const handleRepliesToggle = async () => {
    setIsVisible(!isVisible);

    // this caused it to refetch the replies when the Hide Replies label was clicked
    if (!isVisible && !('replies' in comment)) {
      handleLoadMoreReplies();
    }
  };

  const replies = 'replies' in comment ? comment.replies : [];
  const consensusMigration = usePatronSelector((state) =>
    getFeatureFlag(state, Feature.DISCOURSE_CONSENSUS_MIGRATION)
  );
  const replyPaginationEnabled =
    consensusMigration === 'ReadConsensusWriteBoth' ||
    consensusMigration === 'OnlyConsensus';
  const hasMoreReplies =
    'repliesPageCount' in comment &&
    'repliesCurrentPage' in comment &&
    comment.repliesPageCount > comment.repliesCurrentPage;

  const handleLoadMoreReplies = async () => {
    setIsFetchingReplies(true);
    await onReplies?.(comment);
    setIsFetchingReplies(false);
  };

  // enforcing only 40 comments shown until pagination supported
  const hideReplyCount = comment.replyCount > 40 ? 40 : replies.length;
  const showReplyCount = comment.replyCount > 40 ? 40 : comment.replyCount;

  const replyButtonText =
    !isFetchingReplies && isVisible
      ? t('comments.hide_reply', { count: hideReplyCount })
      : t('comments.show_reply', { count: showReplyCount });

  useEffect(() => {
    // If there's only one reply, it's new and it's the active comment, fetch all replies
    if (
      replyInPlaceEnabled &&
      replies.length === 1 &&
      replies[0].id === context?.activeCommentId &&
      comment.replyCount > 1
    ) {
      // Removing the replies from the comment object, so that the replies are fetched
      if ('replies' in comment) {
        const { replies, ...rest } = comment;
        comment = rest;
      }

      handleLoadMoreReplies();
    }

    setIsVisible(!!replies.length);
  }, [replies.length]);

  return (
    <div className="comment__replies">
      <Button
        variant="text"
        label={replyButtonText}
        isLoading={isFetchingReplies}
        onClick={handleRepliesToggle}
        size="compact"
      />
      <div className={'comment__replies-body'}>
        {isVisible ? (
          <>
            {replies.map((reply) => (
              <CommentListItem
                comment={reply}
                context={context}
                nestedLevel={nestedLevel + 1}
                onEdit={onEdit}
                onDelete={onDelete}
                onReport={onReport}
                onReply={onReply}
                onReplies={onReplies}
                onSubmit={onSubmit}
                onError={onError}
                key={reply.id}
              />
            ))}
            {hasMoreReplies && replyPaginationEnabled && (
              <Button
                size="compact"
                variant="text"
                label={t('comments.show_more_replies')}
                onClick={handleLoadMoreReplies}
              />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CommentReplies;
