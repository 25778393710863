import { useState, useEffect, useCallback, useRef, RefObject } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useCommentFeed, { SortOrder } from '../../../common/use-comment-feed';
import {
  RootPatronState,
  usePatronSelector,
} from '../../../common/use-patron-selector';
import ThinkWarningProvider from '../../../config/context';
import { findFirstScrollableParent } from '../../../lib/dom-utils';
import { Comment, CommentWithReplies } from '../../../models/comments/types';
import { contentOperations } from '../../../models/content/index.js';
import { programSelectors } from '../../../models/program';
import { uiOperations } from '../../../models/ui';
import {
  contentCommentAllRepliesRoutePath,
  contentCommentReplyRoutePath,
} from '../../../patron-routes-constants';
import {
  destroyComment,
  reportComment,
  highlightComment,
  unhighlightComment,
} from '../../../services/comment';

import CommentsFlashMessage, {
  FlashMessage,
} from '../../comments/comment-flash-message';
import { ID as CommentDeleteConfirmDialogID } from '../../comments/comment-delete-confirm-dialog';
import { ID as CommentReportConfirmDialogID } from '../../comments/comment-report-confirm-dialog';
import { CommentContext } from '../../comments/types';

import CommentForm from './comment-form';
import CommentsMain from './comments-main';
import CommentsSingleThread from './comments-single-thread';
import { CommentTranslationContextProvider } from './translations/context';
import './content-comments.scss';
import { Feature, getFeatureFlag } from '../../../models/features/features';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type OwnProps = {
  contentId: number;
  sortOrder: SortOrder;
  commentId?: string;
};

type CommentsProps = StateProps & DispatchProps & OwnProps;

const mapDispatchToProps = {
  addOverlay: uiOperations.addOverlay,
  removeOverlay: uiOperations.removeOverlay,
  incrementCommentCount: contentOperations.incrementCommentCount,
  decrementCommentCount: contentOperations.decrementCommentCount,
};

const mapStateToProps = (state: RootPatronState) => ({
  showThinkWarning: programSelectors.getCommentThinkWarningEnabled(state),
  thinkWarningHtml: programSelectors.getCommentThinkWarningHtml(state),
  thinkWarningLink: programSelectors.getCommentThinkWarningLink(state),
});

const Comments = ({
  contentId,
  sortOrder,
  commentId,
  addOverlay,
  removeOverlay,
  incrementCommentCount,
  decrementCommentCount,
  showThinkWarning,
  thinkWarningHtml,
  thinkWarningLink,
}: CommentsProps) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [context, setContext] = useState<CommentContext>({ contentId });
  const [isFormFocused, setIsFormFocused] = useState(false);
  const [scrollRequested, setScrollRequested] = useState<HTMLDivElement>();
  const replyInPlaceEnabled = usePatronSelector((state) =>
    getFeatureFlag(state, Feature.REPLY_IN_PLACE_ENABLED)
  );

  const hasDeepLinkedReply = useRouteMatch(contentCommentReplyRoutePath);
  const isDisplayRepliesByDefault = useRouteMatch(
    contentCommentAllRepliesRoutePath
  );

  const {
    comments,
    hasNextPage,
    fetchNextPage,
    fetchReplies,
    isFetching,
    message,
    setMessage,
    addComment,
    updateComment,
    updateCommentData,
    removeComment,
    batchTranslateComments,
    translateAllActive,
    setTranslateAllActive,
  } = useCommentFeed({
    contentId,
    sortOrder,
    commentId,
    fetchSingleThreadReplies: !!isDisplayRepliesByDefault,
  });

  const resetContext = useCallback(
    () => setContext({ contentId }),
    [contentId]
  );

  const handleReply = (
    comment: Comment,
    commentRef: RefObject<HTMLDivElement>
  ) => {
    if (!replyInPlaceEnabled) {
      setContext({
        contentId,
        activeCommentId: comment.id,
        replyToId: comment.id,
        replyAuthor: comment.author,
        action: 'reply',
      });
    }

    setScrollRequested(commentRef.current ?? undefined);
  };

  const handleEdit = (comment: Comment) => {
    if (!replyInPlaceEnabled || !comment.replyToId) {
      setContext({
        contentId,
        commentId: comment.id,
        replyToId: comment.replyToId ?? undefined,
        commentText: comment.rawContent,
        attachments: comment.attachments ?? [],
        action: 'edit',
      });
    }
  };

  const handleDelete = (comment: Comment) => {
    addOverlay(CommentDeleteConfirmDialogID, {
      onDeleteConfirm: () => {
        destroyComment(contentId, comment.id).catch((error) => {
          console.error('Failed to delete comment', error);
        });

        removeComment(comment);

        decrementCommentCount(contentId, 1 + (comment.replyCount || 0));

        removeOverlay({ id: CommentDeleteConfirmDialogID, key: undefined });

        resetContext();
      },
    });
  };

  const handleReport = (comment: Comment) => {
    addOverlay(CommentReportConfirmDialogID, {
      onReportConfirm: () => {
        reportComment(contentId, comment.id)
          .then(() => {
            const updatedComment: Comment = {
              ...comment,
              isReported: true,
              canReport: false,
            };

            updateComment(updatedComment);

            removeOverlay({ id: CommentReportConfirmDialogID, key: undefined });

            setMessage({
              text: t('comments.messages.success.report'),
              type: 'success',
              timeout: 1000 * 3,
            });
          })
          .catch((error) => {
            console.error('Failed to report comment', error);
          });
      },
    });
  };

  const handleSubmit = (comment: Comment) => {
    setMessage(undefined);

    setContext({
      contentId,
      activeCommentId: comment.id,
    });

    if (!comment.isEdited) incrementCommentCount(contentId);

    addComment(comment);
  };

  const handleReplies = async (
    comment: Comment | CommentWithReplies,
    reset?: boolean
  ) => {
    if (!('replies' in comment) && !!comment.replyCount) {
      await fetchReplies(comment, reset);
    } else if (
      'replies' in comment &&
      comment.repliesCurrentPage < comment.repliesPageCount
    ) {
      await fetchReplies(comment, reset);
    }
  };

  const handleHighlight = (comment: Comment) => {
    if (!comment.highlightedAt) {
      highlightComment(contentId, comment.id)
        .then(() => {
          const updatedComment = {
            ...comment,
            highlighted: true,
          };

          updateComment(updatedComment);

          if (sortOrder === 'top') {
            fetchNextPage(true).catch((error) => {
              console.error('Failed to fetch next page', error);
            });
          }
        })
        .catch((error) => {
          console.error('Failed to highlight comment', error);
        });
    } else {
      unhighlightComment(contentId, comment.id)
        .then(() => {
          const updatedComment = {
            ...comment,
            highlighted: false,
          };

          updateComment(updatedComment);

          if (sortOrder === 'top') {
            fetchNextPage(true).catch((error) => {
              console.error('Failed to fetch next page', error);
            });
          }
        })
        .catch((error) => {
          console.error('Failed to unhighlight comment', error);
        });
    }
  };

  const handleReset = () => {
    setMessage(undefined);

    resetContext();
  };

  const handleError = (err: FlashMessage) => {
    setMessage(err);
  };

  const scrollToComment = useCallback((comment: HTMLDivElement) => {
    if (!containerRef.current || !inputRef.current) return;

    const commentY = comment.getBoundingClientRect().top;
    const commentHeight = comment.clientHeight;
    const footerY = inputRef.current.getBoundingClientRect().top;

    const offsetY = footerY - commentHeight - commentY;

    const scrollParent =
      findFirstScrollableParent(containerRef.current) || document.body;

    scrollParent.scrollBy({ top: -offsetY, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    resetContext();
  }, [contentId, resetContext]);

  useEffect(() => {
    if (!isFormFocused || !scrollRequested) return;

    // added some delay to wait for the virtual keyboard on mobile devices
    // since it'll affect the footer's Y coordinate
    setTimeout(() => {
      scrollToComment(scrollRequested);
      setScrollRequested(undefined);
    }, 3);
  }, [isFormFocused, scrollRequested, scrollToComment]);

  const isSingleThread = !!commentId;

  return (
    <ThinkWarningProvider
      thinkWarning={{
        showThinkWarning,
        thinkWarningHtml,
        thinkWarningLink,
      }}
    >
      <CommentTranslationContextProvider
        comments={comments}
        batchTranslateComments={batchTranslateComments}
        translateAllActive={translateAllActive}
        setTranslateAllActive={setTranslateAllActive}
        updateCommentData={updateCommentData}
      >
        <div ref={containerRef} className="comments">
          <div ref={inputRef}>
            <CommentsFlashMessage message={message} />
            <CommentForm
              onFormFocus={() => setIsFormFocused(true)}
              onFormBlur={() => setIsFormFocused(false)}
              context={context}
              disabled={!comments.length && isFetching}
              onSubmit={handleSubmit}
              onReset={handleReset}
              onError={handleError}
            />
          </div>

          {isSingleThread && comments.length > 0 ? (
            <CommentsSingleThread
              hasDeepLinkedReply={!!hasDeepLinkedReply}
              comment={comments[0]}
              context={context}
              contentId={contentId}
              isFetching={isFetching}
              onEdit={handleEdit}
              onReply={handleReply}
              onDelete={handleDelete}
              onReport={handleReport}
              onReplies={handleReplies}
              onHighlight={handleHighlight}
            />
          ) : (
            <CommentsMain
              comments={comments}
              context={context}
              hasNextPage={hasNextPage}
              isFetching={isFetching}
              onNextPage={fetchNextPage}
              onEdit={handleEdit}
              onReply={handleReply}
              onDelete={handleDelete}
              onReport={handleReport}
              onReplies={handleReplies}
              onHighlight={handleHighlight}
              onSubmit={handleSubmit}
              onError={handleError}
            />
          )}
        </div>
      </CommentTranslationContextProvider>
    </ThinkWarningProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
