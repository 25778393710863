import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@socialchorus/shared-ui-components';
import { Button, Icon } from '../../ui';
import { useTranslation } from 'react-i18next';
import CommentAttachmentButton from './comment-attachment-button';
import { Feature } from '../../../models/features/features';
import { getFeatureFlag } from '../../../models/features/features';
import { usePatronSelector } from '../../../common/use-patron-selector';

interface CommentFormFooterMoreProps {
  onEmojiIconClick: () => void;
  onMentionIconClick: () => void;
  onAttachmentClick: (file: File) => void;
}

const CommentFormFooterMore: React.FC<CommentFormFooterMoreProps> = ({
  onEmojiIconClick,
  onMentionIconClick,
  onAttachmentClick,
}) => {
  const { t } = useTranslation();
  const consensusMigration = usePatronSelector((state) =>
    getFeatureFlag(state, Feature.DISCOURSE_CONSENSUS_MIGRATION)
  );
  const commentAttachmentsEnabled =
    usePatronSelector((state) =>
      getFeatureFlag(state, Feature.COMMENT_ATTACHMENTS_ENABLED)
    ) &&
    (consensusMigration == 'ReadConsensusWriteBoth' ||
      consensusMigration == 'OnlyConsensus');

  return (
    <div className="mobile-footer">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="action-arrow"
            title={t('comments.actions.label')}
            aria-label={t('comments.actions.label')}
          >
            <Icon type="more_horiz" />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          className="dropdown__popover"
          align="start"
          variant="styled"
        >
          <DropdownMenuItem className="mobile-footer__item">
            <Button
              onClick={onEmojiIconClick}
              aria-label={t('comments.actions.emoji')}
            >
              <Icon type={'insert_emoticon'} />
            </Button>
            <span
              className="mobile-footer__item-text"
              onClick={onEmojiIconClick}
            >
              {t('comments.actions.emoji')}
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem className="mobile-footer__item">
            <Button
              type="button"
              title={t('comments.actions.mention')}
              onClick={onMentionIconClick}
              aria-label={t('comments.actions.mention')}
            >
              <Icon type={'alternate_email'} />
            </Button>
            <span
              className="mobile-footer__item-text"
              onClick={onMentionIconClick}
            >
              {t('comments.actions.mention')}
            </span>
          </DropdownMenuItem>
          {commentAttachmentsEnabled ? (
            <DropdownMenuItem className="mobile-footer__item">
              <CommentAttachmentButton onFileSelect={onAttachmentClick} />
            </DropdownMenuItem>
          ) : null}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default CommentFormFooterMore;
