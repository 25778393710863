import { RootPatronState } from '../../common/use-patron-selector';

export const FeaturesKeyspace = 'features';

export enum Feature {
  REACTIONS_DISABLED = 'EE.Reactions.Disabled',
  EXPERIMENTAL_TARGETED_CHANNEL_LINKS = 'Experimental.TargetedChannelLinks',
  INBOX_MAINTENANCE = 'Assistant.Inbox.Maintenance',
  CHANNELS_TO_TOPICS_ENABLED = 'EE.UI.ChannelsToTopics.Enabled',
  ICON_TYPE = 'EE.UI.NewFoundation.ColorIconTypography.Enabled',
  LIKE_ICON_TYPE = 'EE.UI.NewFoundation.ThumbIcon.Enabled',
  NEW_UI_ENABLED = 'EE.NewUI',
  NEW_SHORTCUTS_ENABLED = 'EE.NewUI.Shortcuts',
  QUICK_LINKS_DISABLED = 'EE.QuickLinksDisabled',
  SUPPORT_IFRAME_ENABLED = 'EE.Embedded.IFrame.Enabled',
  ASSISTANT_ICON_IS_COMPASS = 'EE.UI.AssistantIconToCompass',
  TEST_NEW_UI_FILL_CROP = 'EE.Testing.Cloudinary.FillCrop',
  TEST_CUSTOM_FONT_ALL_EXPERIENCE = 'EE.Testing.CustomFont.ApplyToAll',
  HIDE_HEADER = 'EE.UI.HideHeader',
  TOPIC_LANDING_PAGES_ENABLED = 'EE.TopicLanding.EnabledOnExperience',
  TOPIC_LANDING_PAGES_V2_ENABLED = 'EE.TopicLanding.V2.EnabledOnExperience',
  CONTENT_DETAIL_NEW = 'EE.ContentDetail.New',
  CUSTOM_URLS_ENABLED = 'EE.CustomUrls.Enabled',
  FLAG_POST_ENABLED = 'EE.FlagPost.Enabled',
  COMMENT_ASSISTANCE_ENABLED = 'EE.AI.CommentAssistance.Enabled',
  COMMENT_ATTACHMENTS_ENABLED = 'EE.CommentAttachments.Enabled',
  NESTED_REPLIES_ENABLED = 'EE.NestedReplies.Enabled',
  CONTENT_BACK_BUTTON_REMOVED = 'EE.ContentDetail.BackButtonRemoved',
  CONTENT_DETAIL_HIDE_HEADER = 'EE.ContentDetail.HideHeader',
  TAG_USER_ENABLED = 'EE.TagUser.Enabled',
  IMPROVED_SEARCH = 'EE.SearchImprovements.Enabled',
  IMPROVED_SEARCH_SHORTCUTS_DOCUMENTS = 'EE.SearchImprovements.ShortcutsDocuments.Enabled',
  DISCOURSE_CONSENSUS_MIGRATION = 'EE.DiscourseConsensusMigration',
  REPLY_IN_PLACE_ENABLED = 'EE.ReplyInPlace.Enabled',

  BOX_INTEGRATION = 'License.Integration.BoxKnowledgeManagement',

  /** Temporary solution for Davey's customization, that got turned into a permananet solution for dynamic signal customers that migrate while having a vanity domain */
  CUSTOMIZED_DAVEY_MESSAGE = 'EE.NativeRedirect.CustomizedMessage.Davey',
  COMMENT_TRANSLATION_ENABLED = 'EE.TranslateComments.Enabled',
  TESCO_Q3 = 'EE.TescoQ3',
  NOTIFICATION_CENTER_ENABLED = 'EE.NotificationCenter',
  UGC_TOPIC_LIMITS = 'EE.UserGeneratedContent.TopicLimitsEnabled',
  /** Temporary until we can build UI to drive these */
  NEWEST_TOPIC_ENABLED = 'EE.EnableNewestTopicSection',
  RECOMMENDED_TOPIC_ENABLED = 'EE.EnableRecommendedTopicSection',
  POPULAR_TOPIC_ENABLED = 'EE.EnablePopularTopicSection',
  /** Experimental */
  ASSISTANT_DISABLED = 'EE.Assistant.Disabled',
}

/**
 *  Actions
 */

export enum ActionType {
  SET_FLAGS = 'patron/features/SET',
}

interface SetFlagsAction {
  type: ActionType.SET_FLAGS;
  features: Record<string, string | boolean>;
}

type FeatureFlagsAction = SetFlagsAction;

/**
 * Action creators
 */

export function setFeatureFlags(
  features: SetFlagsAction['features']
): SetFlagsAction {
  return {
    type: ActionType.SET_FLAGS,
    features,
  };
}

/**
 * Selectors
 */

export function getFeatureFlags(state: RootPatronState) {
  return state[FeaturesKeyspace]?.features;
}

export function getFeatureFlag(state: RootPatronState, flag: Feature) {
  return getFeatureFlags(state)?.[flag];
}

/**
 * Reducer
 */

const initialState = {
  features: {} as Record<string, string>,
};

export function featureFlagReducer(
  state = initialState,
  action: FeatureFlagsAction
) {
  switch (action.type) {
    case ActionType.SET_FLAGS:
      return {
        ...state,
        features: action.features,
      };
    default:
      return state;
  }
}

export default featureFlagReducer;
