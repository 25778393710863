import { useTranslation } from 'react-i18next';
import { Button, Icon } from '../../ui';
import { useRef } from 'react';
import { Feature, getFeatureFlag } from '../../../models/features/features';
import { usePatronSelector } from '../../../common/use-patron-selector';
import cx from 'classnames';

interface CommentAttachmentButtonProps {
  onFileSelect: (file: File) => void;
}

const CommentAttachmentButton = ({
  onFileSelect,
}: CommentAttachmentButtonProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const replyInPlaceEnabled = usePatronSelector((state) =>
    getFeatureFlag(state, Feature.REPLY_IN_PLACE_ENABLED)
  );

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const files = event.target.files;
    if (files && files[0]) {
      onFileSelect(files[0]);
      event.target.value = '';
    }
  };

  const handleButtonClick = (): void => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <Button
        type="button"
        title={t('comments.actions.attachments')}
        onClick={handleButtonClick}
      >
        <Icon type="photo" />
      </Button>
      {replyInPlaceEnabled ? (
        <span
          className={cx('comment-attachment-button-text', {
            'comment-attachment-button-text--visible': replyInPlaceEnabled,
          })}
          onClick={handleButtonClick}
        >
          {t('comments.actions.attachments')}
        </span>
      ) : null}
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/jpeg, image/png"
        id="comment-attachment-input"
        type="file"
        onChange={handleFileChange}
      />
    </>
  );
};

export default CommentAttachmentButton;
