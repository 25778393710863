import { ComponentProps } from 'react';
import CommentListItem from './comment-list-item';
import CommentsEmpty from '../../comments/comments-empty';

type CommentListProps = {
  comments: ComponentProps<typeof CommentListItem>['comment'][];
} & Pick<
  ComponentProps<typeof CommentListItem>,
  | 'context'
  | 'onReply'
  | 'onEdit'
  | 'onDelete'
  | 'onReport'
  | 'onReplies'
  | 'onHighlight'
  | 'onSubmit'
  | 'onError'
>;

const CommentList = ({
  comments,
  context,
  onReply,
  onEdit,
  onDelete,
  onReport,
  onReplies,
  onHighlight,
  onSubmit,
  onError,
}: CommentListProps) => {
  return comments.length ? (
    <div className="comment__list">
      {comments.map((comment) => {
        return (
          <CommentListItem
            comment={comment}
            context={context}
            onReply={onReply}
            onEdit={onEdit}
            onDelete={onDelete}
            onReport={onReport}
            onReplies={onReplies}
            onHighlight={onHighlight}
            onSubmit={onSubmit}
            onError={onError}
            key={comment.id}
          />
        );
      })}
    </div>
  ) : (
    <CommentsEmpty />
  );
};

export default CommentList;
