import {
  useRef,
  ReactEventHandler,
  RefObject,
  ComponentProps,
  useState,
  useEffect,
  useContext,
} from 'react';
import parse, { Element, HTMLReactParserOptions } from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { timeAgoOrDate } from '../../../lib/date-formatter';
import { trackCommentEvent } from '../../../models/comments/analytics';
import { Comment, CommentWithReplies } from '../../../models/comments/types';

import CommentAuthorAvatar from '../../comments/comment-author-avatar';
import CommentAuthorName from '../../comments/comment-author-name';
import CommentActionMore from '../../comments/comment-action-more';
import { CommentContext } from '../../comments/types';
import { Icon } from '../../ui';

import { CommentActionLike } from './actions/CommentActionLike';
import { CommentActionReply } from './actions/CommentActionReply';
import CommentReplies from './comment-replies';
import { CommentActionTranslate } from './actions/CommentActionTranslate';
import useFeatureFlag from '../../../common/use-feature-flag';
import { Feature, getFeatureFlag } from '../../../models/features/features';
import useInview from '../../../common/use-inview';
import {
  trackSeeOriginalComment,
  trackTranslateComment,
} from '../../../models/comment-translations';
import { CommentTranslationContext } from './translations/context';
import { normalizeLocale } from '../../../lib/language-helper';
import {
  Icon as SharedIcon,
  TooltipContent,
  TooltipRoot,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';
import { useDispatch } from 'react-redux';
import { uiOperations } from '../../../models/ui';
import { ID as AttachmentModalID } from './modal/attachment-modal';
import { usePatronSelector } from '../../../common/use-patron-selector';
import CommentAttachment from './comment-attachment';
import { CommentAttachment as CommentAttachmentType } from '../../../models/comments/types';
import CommentForm from './comment-form';
import { FlashMessage } from '../../comments/comment-flash-message';
const UNSUPPORTED_LANGUAGE = 'unsupported';

type CommentListItemProps = Pick<
  ComponentProps<typeof CommentReplies>,
  'onReplies'
> & {
  comment: Comment | CommentWithReplies;
  context: CommentContext;
  hideReplies?: boolean;
  nestedLevel?: number;
  onReply?: (comment: Comment, ref: RefObject<HTMLDivElement>) => void;
  onEdit?: (comment: Comment) => void;
  onDelete?: (comment: Comment) => void;
  onReport?: (comment: Comment) => void;
  onHighlight?: (comment: Comment) => void;
  onSubmit?: (comment: Comment) => void;
  onError?: (err: FlashMessage) => void;
};

const CommentListItem = ({
  comment,
  context,
  hideReplies,
  nestedLevel = 0,
  onReply,
  onEdit,
  onDelete,
  onReport,
  onReplies,
  onHighlight,
  onSubmit,
  onError,
}: CommentListItemProps) => {
  const { translateComment, batchToTranslateAllComments, translateAllActive } =
    useContext(CommentTranslationContext);

  const el = useRef<HTMLDivElement>(null);

  // track if the user has attempted to translate the comment, so we don't infinitely try to translate it if it's failing
  const [hasAttemptedTranslation, setHasAttemptedTranslation] = useState(false);
  const [hasTranslationError, setHasTranslationError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    t,
    i18n: { language: userLanguage },
  } = useTranslation();

  const translationEnabled = useFeatureFlag(
    Feature.COMMENT_TRANSLATION_ENABLED
  );

  const replyInPlaceEnabled = usePatronSelector((state) =>
    getFeatureFlag(state, Feature.REPLY_IN_PLACE_ENABLED)
  );

  const isActive = comment.id === context.activeCommentId;

  const isTranslateable = Boolean(translationEnabled && comment.isTranslatable);
  const isTranslated =
    isTranslateable &&
    normalizeLocale(comment.displayLanguage) === normalizeLocale(userLanguage);
  const isTranslationInProgress = !!comment.translationInProgress; //managed by both the translate all button and the individual comment translate button

  const shouldAutoTranslate =
    isTranslateable && translateAllActive !== isTranslated;

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !visible ||
      hasAttemptedTranslation ||
      isTranslationInProgress ||
      !shouldAutoTranslate
    ) {
      return;
    }

    setHasAttemptedTranslation(true);
    batchToTranslateAllComments(comment, () => setHasTranslationError(true));
  }, [
    batchToTranslateAllComments,
    comment,
    hasAttemptedTranslation,
    isTranslationInProgress,
    shouldAutoTranslate,
    visible,
  ]);

  useEffect(() => {
    // reset this state when "translate all" is toggled
    setHasAttemptedTranslation(false);
  }, [translateAllActive]);

  useInview({
    ref: el,
    onInview: () => setVisible(true),
    onOutview: () => setVisible(false),
  });

  const translateAction = () => {
    const translate = !isTranslated;

    const analyticsPayload = {
      original_language: comment.language,
      translated_language: userLanguage,
      content_id: comment.contentId,
      comment_id: comment.id,
    };

    if (translate) {
      trackTranslateComment(analyticsPayload);
    } else {
      trackSeeOriginalComment(analyticsPayload);
    }

    translateComment(comment, translate, () => setHasTranslationError(true));

    setHasAttemptedTranslation(true);
  };

  const formattedTime = timeAgoOrDate(comment.createdAt);

  const className = cx('comment-list-item comment', {
    'comment--active': isActive,
  });

  const handleAuthorClick = () => {
    trackCommentEvent('avatarClick', comment, !!comment.replyToId);
  };

  const hideImage: ReactEventHandler = (e) => {
    if (e.target instanceof HTMLImageElement) {
      e.target.style.display = 'none';
    }
  };

  const handleAttachmentClick = (attachment: CommentAttachmentType) => {
    dispatch(
      uiOperations.displayOverlay(AttachmentModalID, {
        action: 'view',
        existingAttachment: attachment,
      })
    );
  };

  const handleReplySubmitClick = (comment: Comment) => {
    setShowReplyForm(false);
    onSubmit?.(comment);
  };

  // dynamically override discourse-created comment structure
  const parseOpts: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (
        domNode instanceof Element &&
        domNode.type === 'tag' &&
        domNode.name === 'img' &&
        domNode.attribs.class === 'preview-image-display'
      ) {
        return (
          <img
            onError={hideImage}
            className="preview-image-display"
            src={domNode.attribs.src}
            alt=""
          />
        );
      }
    },
  };

  const isSupportedLanguage = comment.language !== UNSUPPORTED_LANGUAGE;
  const unsupportedLanguageLabel = t(
    'comments.actions.translate.unsupported_tooltip'
  );

  const consensusMigration = usePatronSelector((state) =>
    getFeatureFlag(state, Feature.DISCOURSE_CONSENSUS_MIGRATION)
  );
  const readConsensus =
    consensusMigration === 'ReadConsensusWriteBoth' ||
    consensusMigration === 'OnlyConsensus';

  const nestedRepliesEnabled = useFeatureFlag(Feature.NESTED_REPLIES_ENABLED);
  const canReply =
    readConsensus && nestedRepliesEnabled
      ? nestedLevel < 2
      : !comment.replyToId;

  return (
    <>
      {isEditing && replyInPlaceEnabled && comment.replyToId ? (
        <CommentForm
          context={{
            ...context,
            commentId: comment.id,
            contentId: comment.contentId,
            replyToId: comment.replyToId ?? undefined,
            action: 'edit',
            commentText: comment.rawContent,
            attachments: comment.attachments ?? [],
          }}
          onSubmit={(editedComment) => {
            setIsEditing(false);
            onSubmit?.(editedComment);
          }}
          onError={onError}
        />
      ) : (
        <div className={className} ref={el}>
          <div className="comment__avatar">
            <CommentAuthorAvatar
              author={comment.author}
              onClick={handleAuthorClick}
            />
          </div>

          <div className="comment__body">
            <div className="comment__content">
              <div className="comment__content--header">
                {comment.highlightedAt ? <Icon type="star">star</Icon> : null}

                <CommentAuthorName author={comment.author} />

                <div className="time-stamp">{formattedTime}</div>
              </div>

              <div className="comment__content--text">
                {parse(comment.content, parseOpts)}
                {comment.isEdited ? (
                  <small>{t('comments.state.edited')}</small>
                ) : null}
              </div>
              {comment.attachments && comment.attachments.length > 0 ? (
                <div className="comment__content--attachments">
                  {comment.attachments.map((attachment) => (
                    <CommentAttachment
                      key={attachment.url}
                      attachment={attachment}
                      onClick={() => handleAttachmentClick(attachment)}
                    />
                  ))}
                </div>
              ) : null}
            </div>
            <CommentActionMore
              comment={comment}
              onEdit={(comment) => {
                setIsEditing(true);
                onEdit?.(comment);
              }}
              onDelete={onDelete}
              onReport={onReport}
              onHighlight={onHighlight}
            />

            <div className="comment__actions">
              <CommentActionLike comment={comment} />
              {canReply ? (
                <CommentActionReply
                  comment={comment}
                  onClick={(comment: Comment) => {
                    setShowReplyForm(true);
                    onReply?.(comment, el);
                  }}
                />
              ) : null}
              {isTranslateable ? (
                <div className="comment__actions__translate">
                  <CommentActionTranslate
                    comment={comment}
                    onClick={translateAction}
                    disabled={comment.language === UNSUPPORTED_LANGUAGE}
                    isTranslated={isTranslated}
                    isLoading={comment.translationInProgress}
                    hasError={hasTranslationError}
                  />

                  {!isSupportedLanguage ? (
                    <TooltipRoot delayDuration={0}>
                      <TooltipTrigger asChild>
                        <SharedIcon size={20}> info</SharedIcon>
                      </TooltipTrigger>
                      <TooltipContent
                        className="tooltip-content"
                        description={unsupportedLanguageLabel}
                        sideOffset={5}
                      />
                    </TooltipRoot>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}

      {showReplyForm && replyInPlaceEnabled && (
        <CommentForm
          context={{
            ...context,
            contentId: comment.contentId,
            action: 'reply',
            replyToId: comment.id,
            replyAuthor: comment.author,
          }}
          disabled={!replyInPlaceEnabled}
          onSubmit={handleReplySubmitClick}
          onError={onError}
        />
      )}

      {!hideReplies && comment.replyCount ? (
        <CommentReplies
          comment={comment as CommentWithReplies}
          context={context}
          nestedLevel={nestedLevel}
          onEdit={onEdit}
          onDelete={onDelete}
          onReply={onReply}
          onReport={onReport}
          onReplies={onReplies}
          onSubmit={onSubmit}
          onError={onError}
        />
      ) : null}
    </>
  );
};

export default CommentListItem;
